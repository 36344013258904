<template>
  <CRow>
    <CModal
      :show.sync="dangerModal"
      centered
      :footer="footer"
      :title="$t('signOut')"
      id="userLogoutModal"
    >
      <h4 class="text-center" id="userLogoutDialogText">{{ $t('dialogSignOut') }}</h4>
      <br />
      <h5 class="text-center" id="userLogoutConfirmText">{{ $t('confirmSignOut') }}</h5>
      <template #footer-wrapper id="userLogoutFooterRow">
        <CRow class="justify-content-center col-md-12">
          <CCol col="4" id="userLogoutSubmitCol">
            <CCol col="12" v-if="loadingButton === true" id="userLogoutLoadingCol">
              <CButton id="userLogoutSubmitButton" color="danger" @click="logout" block>
                {{ $t('submit') }}
              </CButton>
            </CCol>
            <CCol col="12" v-else-if="loadingButton === false" id="userLogoutDisabledCol">
              <CButton id="userLogoutDisabledButton" color="danger" disabled block>
                <CSpinner id="userLogoutSpinner" color="white" size="sm" />
              </CButton>
            </CCol>
          </CCol>
          <CCol col="4" id="userLogoutCancelCol">
            <CButton color="light" id="userLogoutCancelButton" @click="$router.go(-1)" block>
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </CRow>
</template>

<script>
import firebase from 'firebase/app'
export default {
  data() {
    return {
      dangerModal: true,
      footer: '',
      loadingButton: true,
    }
  },
  methods: {
    logout() {
      this.loadingButton = false
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          localStorage.clear()
          window.location.href = '/login'
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
